import React, { useState } from "react"
import styled from "styled-components"
import { injectIntl } from "gatsby-plugin-intl"
import { v4 } from "uuid"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RequestForm from "../components/RequestForm"
import { Link, graphql } from "gatsby"
import {
  BreadCrumb,
  Section,
  SectionTitle,
  SectionDescription,
  FormTwoCol,
} from "../components/Section"
import { ArrowRightIcon, BulletIcon } from "../components/Icons"
import BreakpointUp from "../components/Media/BreakpointUp"
import BreakpointDown from "../components/Media/BreakpointDown"
import DefaultButton from "../components/Button/DefaultButton"
import Image from "../components/image"
import QuotePopup from "../components/QuotePopup"

const StepWrap = styled.div`
  max-width: 1010px;
  width: 100%;
  margin: 0 auto;
  .step-item {
    .icon {
      svg {
        height: 60px;
      }
    }

    &:nth-child(even) {
      .step {
        flex-direction: row-reverse;
        .icon {
          ${BreakpointUp.lg`                        
                        margin-left: auto;
                    `}
        }
        .step-block {
          &:before {
            ${BreakpointUp.lg`                                                
                            left:0;
                            right:auto;
                        `}
          }
          &:after {
            ${BreakpointUp.lg`                                                
                            left: 7px;
                            right:auto;
                        `}
          }
        }
        .step-left {
          &:after {
            right: auto;
            left: 15px;
          }
        }
        .step-right {
          ${BreakpointUp.lg`                
                        text-align:right;
                    `}
        }
      }
    }
    &:first-child {
      .step {
        .icon {
          &:before {
            display: none;
          }
        }
      }
    }
    &:last-child {
      .step {
        .icon {
          &:after {
            display: none;
          }
        }
      }
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
`
const StepItem = styled.div`
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 90%;
    height: 2px;
    background-image: linear-gradient(
      to right,
      #cfcfcf 50%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 12px 4px;
    background-repeat: repeat-x;
    left: 50%;
    transform: translateX(-50%);
  }
`
const Step = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -15px;
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 40px;
  text-align: center;
  ${BreakpointUp.lg` 
    text-align:left;
    `}
`
const StepLeft = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  &:after {
    ${BreakpointUp.lg`  
            content: '';
            position: absolute;
            top: 50%;
            right: 15px;
            width: 50%;
            height: 2px;
            background-image: linear-gradient(to right,#CFCFCF 50%,rgba(255,255,255,0) 0%);
            background-position: bottom;
            background-size: 12px 2px;
            background-repeat: repeat-x;
        `}
  }
  ${BreakpointUp.lg`
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    `}
`
const StepRight = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;

  ${BreakpointUp.lg`     
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    `}
`
const StepBlock = styled.div`
  position: relative;

  &:before,
  &:after {
    display: none;
    ${BreakpointUp.lg`      
            display:block;                                                     
            content: '';
            position: absolute;
            top: 50%;
            transform:translateY(-50%);
            z-index: 1;
        `}
  }
  &:before {
    right: 0;
    width: 34px;
    height: 34px;
    border: 2px solid #fff;
    border-radius: 50%;
    background: #f6f6f6;
  }
  &:after {
    right: 7px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #cfcfcf;
  }
`
const StepIcon = styled.div`
  width: 108px;
  height: 108px;
  border-radius: 50%;
  border: 1px solid rgba(33, 31, 40, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
  background: #f6f6f6;
  margin: 0 auto 15px auto;
  ${BreakpointUp.lg`      
        margin:0;
    `}
  &:after, &:before {
    display: none;
    ${BreakpointUp.lg` 
            display:block;     
            content: '';
            position: absolute;
            left: 50%;
            height: 40%;
            width: 3%;
            background-image: linear-gradient(to top,#CFCFCF 50%,rgba(255,255,255,0) 0%);
            background-position: bottom;
            background-size: 2px 12px;
            background-repeat: repeat-y;
        `}
  }
  &:after {
    top: 100%;
  }
  &:before {
    bottom: 100%;
  }
`
const StepInner = styled.div`
  width: 86px;
  height: 86px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper {
    width: 100%;
    max-width: 60px;
    margin: 0 auto;
  }
`
const StepContent = styled.div`
  ${BreakpointDown.lg`     
        max-width:565px;
        width:100%;
        margin: 0 auto;
    `}
  strong {
    color: #211f28;
    font-family: "Rajdhani", sans-serif;
    font-weight: 700;
    margin: 0 0 10px;
    font-size: 18px;
    line-height: 24px;
    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 26px;
    }
    @media (min-width: 992px) {
      font-size: 26px;
      line-height: 30px;
    }
  }
  p {
    margin-bottom: 0;
    + p {
      margin-top: 15px;
    }
  }
`
const BgImage = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  bottom: 0;
  .gatsby-image-wrapper {
    width: 60%;
    height: 100%;
    right: 0;
    position: absolute !important;
    top: 0;
  }
  &:after {
    background-color: #eee;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 40%;
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`

const Card = styled.div`
  margin-bottom: 20px;
  position: relative;
  ${BreakpointUp.md`
		height:100%;
	`}
`
const CardIcon = styled.div`
  margin-bottom: 10px;
  .gatsby-image-wrapper {
    width: 66px;
    margin: auto;
  }
`
const CardBody = styled.div`
  margin: 0;
  padding: 50px 30px;
  text-align: center;
  background-color: #fff;
  z-index: 1;
  position: relative;
  ${BreakpointUp.md`
		height:100%;
	`}
`
const CardTitle = styled.div`
  color: #211f28;
  font-size: 26px;
  line-height: 38px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
`
const CardText = styled.p`
  margin: 0;
`

const CardHorizontal = styled.div`
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  position: relative;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
  .cardhorizontal-item {
    position: relative;
    + .cardhorizontal-item {
      &:before {
        display: block;
        content: "";
        position: absolute;
        z-index: 2;
        left: 0;
        top: 50%;
        ${BreakpointUp.lg`
            height: 75%;
            width: 2%;
            background-image: linear-gradient(to top,#CFCFCF 50%,rgba(255,255,255,0) 0%);
            background-position: bottom;
            background-size: 1px 12px;
            background-repeat: repeat-y;
            transform: translateY(-50%);
        `}
      }
    }
  }
`
const CardHorizontalItem = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.lg`
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    `}
`

const BackgroundImage = styled.div`
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`
const TopHeaderSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  position: relative;
  ${BreakpointUp.md`
    text-align: left;
  `}
  ${BreakpointUp.lg`
    padding-left: calc((100% - 1380px) / 2);
  `}
`
const CardColumn = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.lg`
    width: 50%;
  `}
`
const TopHeaderTitle = styled.h1`
  margin: 0 0 5px;
`
const TopHeaderFigure = styled.div`
  position: relative;
  padding-left: 15px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    transform: matrix(0.87, 0.5, -0.5, 0.87, 0, 0);
  }
  &:before {
    width: 83px;
    height: 83px;
    border: 8px solid #f9f9f9;
    top: 32px;
    right: 35px;
  }
  &:after {
    width: 50px;
    height: 50px;
    border: 5px solid #211f28;
    top: 0;
    right: 115px;
  }
  & .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
`
const TopHeaderBody = styled.div`
  position: relative;
  padding: 0;
  &:after {
    content: "";
    width: 42px;
    height: 42px;
    border: 4px solid #211f28;
    transform: matrix(0.87, 0.5, -0.5, 0.87, 0, 0);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    display: none;
    ${BreakpointUp.md`    
      display:block;
    `}
  }
`
const TopHeaderContent = styled.div`
  position: relative;
  z-index: 1;
  padding: 20px 15px 30px 15px;
  ${BreakpointUp.md`    
    padding:20px 15px 100px 15px;
  `}
`
const TopHeaderText = styled.div`
  p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 30px;
    ${BreakpointUp.lg`
      font-size: 18px;
      line-height: 30px;
    `}
  }
`
const OptionList = styled.div`
  display: inline-flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  padding-left: 15px;
`
const OptionItem = styled.div`
  background-color: #fff;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 3px 20px rgba(14, 20, 28, 0.1);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  position: relative;
  &.item {
    + .item {
      margin-top: 35px;
    }
  }
  .icon {
    .gatsby-image-wrapper {
      width: 36px;
    }
    + .text {
      margin-left: 10px;
    }
  }
  .text {
    color: #211f28;
    font-family: "Rajdhani", sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  }
  &:nth-child(1) {
    max-width: 360px;
    width: 100%;
    left: 20%;
  }
  &:nth-child(2) {
    max-width: 370px;
    width: 100%;
  }
  &:nth-child(3) {
    max-width: 380px;
    width: 100%;
    left: 10%;
  }
  &:nth-child(4) {
    max-width: 400px;
    width: 100%;
    left: 5%;
  }
`

function FinancingPage({ data, intl }) {
  const [isVisibleQuote, setQuoteVisible] = useState(false)
  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }
  const pageData = data.contentfulFinancingPage
  return (
    <Layout>
      <SEO title={pageData.metaTitle} description={pageData.metaDescription} />
      <BreadCrumb>
        <div className="container">
          <Link to="/">
            {intl.formatMessage({ id: "Home" })} <BulletIcon />
          </Link>
          <span>{pageData.pageName}</span>
        </div>
      </BreadCrumb>
      <Section
        pt="200px"
        pb="90px"
        xpt="70px"
        xpb="60px"
        bgColor="#F6F6F6"
        bdrBottom="#D8D8D8"
      >
        <BgImage>
          <Image name="banner-bg.png" alt="banner-bg" />
        </BgImage>
        <TopHeaderSection>
          <CardColumn>
            <TopHeaderBody>
              <TopHeaderContent>
                <TopHeaderTitle>{pageData.heroTitle}</TopHeaderTitle>
                <TopHeaderText>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageData.heroDescription.childMarkdownRemark.html,
                    }}
                  />
                  <button
                    onClick={() => setQuoteVisible(true)}
                    className="btn-lg"
                  >
                    <DefaultButton icon={<ArrowRightIcon />} text="Apply Now" />
                  </button>
                </TopHeaderText>
              </TopHeaderContent>
            </TopHeaderBody>
          </CardColumn>
          <CardColumn>
            <OptionList>
              {pageData.features.map(item => (
                <OptionItem className="item" key={v4()}>
                  <div className="icon">
                    <Img fluid={item.fluid} alt={item.description} />
                  </div>
                  <span className="text">{item.title}</span>
                </OptionItem>
              ))}
            </OptionList>
            <TopHeaderFigure>
              <Img
                fluid={pageData.heroImage.fluid}
                alt={pageData.heroImage.description}
              />
            </TopHeaderFigure>
          </CardColumn>
        </TopHeaderSection>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionTitle maxWidth="860px">
            {pageData.processSectionTitle}
          </SectionTitle>
          <SectionDescription maxWidth="1120px" mb="50px">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  pageData.processSectionDescription.childMarkdownRemark.html,
              }}
            />
          </SectionDescription>
          <StepWrap>
            {pageData.processImages.map(item => (
              <StepItem className="step-item" key={v4()}>
                <Step className="step">
                  <StepLeft className="step-left">
                    <StepBlock className="step-block">
                      <StepIcon className="icon">
                        <StepInner>
                          <Img fluid={item.fluid} alt={item.description} />
                        </StepInner>
                      </StepIcon>
                    </StepBlock>
                  </StepLeft>
                  <StepRight className="step-right">
                    <StepContent>
                      <strong>{item.title}</strong>
                      <p>{item.description}</p>
                    </StepContent>
                  </StepRight>
                </Step>
              </StepItem>
            ))}
          </StepWrap>
        </div>
      </Section>
      <Section pt="90px" pb="205px" xpt="60px" xpb="60px" bgColor="#F9F9F9">
        <BackgroundImage>
          <Img
            fluid={pageData.featureSectionImage.fluid}
            alt={pageData.featureSectionImage.description}
          />
        </BackgroundImage>
        <div className="container">
          <SectionTitle>{pageData.featureSectionTitle}</SectionTitle>
          <SectionDescription maxWidth="1120px" mb="30px">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  pageData.featureSectionDescription.childMarkdownRemark.html,
              }}
            />
          </SectionDescription>
        </div>
      </Section>
      <Section
        pt="0"
        pb="0"
        xpt="60px"
        xpb="60px"
        mt="-175px"
        xmt="0"
        bgColor="transparent"
      >
        <div className="container">
          <CardHorizontal>
            {pageData.features.map(feature => (
              <CardHorizontalItem className="cardhorizontal-item" key={v4()}>
                <Card className="card">
                  <CardBody className="card-body">
                    <CardIcon>
                      <Img fluid={feature.fluid} alt={feature.description} />
                    </CardIcon>
                    <CardTitle>{feature.title}</CardTitle>
                    <CardText>{feature.description}</CardText>
                  </CardBody>
                </Card>
              </CardHorizontalItem>
            ))}
          </CardHorizontal>
        </div>
      </Section>
      <Section pt="90px" pb="110px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionTitle maxWidth="860px">
            {pageData.formSectionTitle}
          </SectionTitle>
          <SectionDescription maxWidth="1120px" mb="30px">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  pageData.formSectionDescription.childMarkdownRemark.html,
              }}
            />
          </SectionDescription>
          <FormTwoCol>
            <RequestForm intl={intl} />
          </FormTwoCol>
        </div>
      </Section>
      <QuotePopup
        isVisible={isVisibleQuote}
        onClose={() => setQuoteVisible(false)}
        intl={intl}
      />
    </Layout>
  )
}

export default injectIntl(FinancingPage)

export const query = graphql`
  query FinancingPageQuery($slug: String, $locale: String) {
    contentfulFinancingPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      node_locale
      pageName
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
        description
      }
      processSectionTitle
      processSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      processImages {
        title
        description
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      featureSectionTitle
      featureSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      featureSectionImage {
        fluid(quality: 100) {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
        description
      }
      features {
        fluid(quality: 100) {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
        title
        description
      }
      formSectionTitle
      formSectionDescription {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
